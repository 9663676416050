<template>
  <div
    v-loading="loading"
    element-loading-background="rgba(122, 122, 122, 0.1)"
    element-loading-text="Please wait"
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    style="z-index: 1"
  >
    <div class="card card-lead-by-sources">
      <!-- v-model:modelPolicyStatus="filter.policy_status" -->
      <vc-report-filter
        v-model:modelStartDate="filter.start_date"
        v-model:modelEndDate="filter.end_date"
        v-model:modelSearch="filter.search"
        show-export-reset-first-row
        show-export-reset-second-row
        :show-export-first="false"
        :show-reset-second="false"
        @reset="reset"
        @export="exportData"    
        showTextFilterFirst
        :showExportResetFirstRow="true"
        :showExportResetSecondRow="true"
        :export-url="exportUrl"
        :textFilterPlaceHolder="'Campaign Name'"
      />

      <div class="card-body card-custom-spacing-tblf-10 bg-white">
        <div class="pt-0 p-0" v-if="marketingReport && marketingReport.length > 0">
          <div id="displayResponseData">
            <!-- class="table table-hover bg-bai-table-header table-bai-striped table-row-bordered border gy-7 gs-7 mb-0" -->
            <el-table :data="marketingReport">
              <el-table-column
                v-for="list in campaign"
                :key="list.label"
                :fixed="list.isFixed"
                :width="list.width"
              >
                <template #header>
                  <span v-html="list.label" />
                </template>
                <template #default="scope">
                  <span v-if="list.isIndex" :class="{'text-blue':scope.row['isTotal']}">
                    {{ scope.$index + 1 }}
                  </span>
                  <span v-else-if="list.isFormat"  :class="{'text-blue':scope.row['isTotal']}">
                    {{ common.setDateTimeOnly(scope.row[list.value]) }}
                  </span>
                  <span v-else-if="list.isCount"  :class="{'text-blue':scope.row['isTotal']}">
                    <span
                      v-for="(n, index) in scope.row[list.value]"
                      :key="index"
                    >
                      {{ index + "(" + n + "), " }}
                    </span>
                  </span>
                  
                  
                  <el-popover
                    v-else-if="list.isWip"
                    placement="left"
                    width="180"
                    trigger="hover"
                    :show-after="200"
                    :hide-after="0"
                  >
                    <template #reference>
                      <span
                        :class="{'text-blue':scope.row['isTotal']}"
                        v-html="scope.row[list.value]"
                      />
                    </template>
                    <template #default>
                      <div class="card">
                        <div class="card-body p-2">
                          <table class="text-gray-600">
                            <tr class="text-gray-600" style="background-color: #fff">
                              <th
                                scope="col"
                                nowrap
                                colspan="2"
                                class="text-center p-2"
                              >
                                WIP Leads
                              </th>
                            </tr>
                            <tr class="text-gray-600" style="background-color: #fff">
                              <td scope="col" class="p-2"  nowrap>
                                New Leads <br/>
                                {{ scope.row.newLeads }}
                              </td>
                              <td scope="col" class="p-2"  nowrap>
                                Pending Leads <br/>
                                {{ scope.row.pendingLeads }}
                              </td>
                            </tr>
                           
                          </table>
                        </div>
                      </div>
                    </template>
                  </el-popover>

                  <span
                    v-else
                    :class="{'text-blue':scope.row['isTotal']}"
                    v-html="scope.row[list.value]"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-else>
          <div v-if="loading">
            <span>Loading Information Please Wait...</span>
          </div>
          <div v-else class="container-fluid p-5">
            <div class="row">
              <div class="col-12">
                <el-empty description="No Records Found" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Report from "@/core/services/Callable/Report";
import { store } from "@/store";
import moment from "moment";
import { campaign } from "@/core/utils/campaign";

const filter = ref({
    start_date: "",
    end_date: "",
    search: "",
})

const loading = ref(false);
const exportUrl = ref('')

const marketingReport = computed(() => {
  return store.getters.getMarketingReport;
});

onMounted(async () => {
  setCurrentPageBreadcrumbs("Marketing Report", [{ label: "Reports", value: "" }]);
  filter.value.start_date = moment().startOf("month").format("YYYY-MM-DD");
  filter.value.end_date = moment().endOf("month").format("YYYY-MM-DD");
  await searchData(filter.value);
});

watch(
  [() => filter.value],
  async ([newValue], [oldValue]) => {
    exportUrl.value = '';
    searchData(newValue);
  },
  {
    deep: true,
  }
);

const searchData = async (value) => {
  loading.value = true
  const data = await Report.getMarketingReport(value);
  if(value.export){
    exportUrl.value = data
      // window.open(data)
  }
  loading.value = false

};

const reset = async () => {
  filter.value.search = "";
  filter.value.start_date = moment().startOf("month").format("YYYY-MM-DD");
  filter.value.end_date = moment().endOf("month").format("YYYY-MM-DD");
  exportUrl.value = '';
  await searchData(filter.value);
};

const exportData = () => {
  const payload = {
    ...filter.value,
    export:true
  }
  searchData(payload)
};
</script>

<style>
.text-blue {
    color: #2162f6 !important;
}
</style>