export const campaign = [
    {
        label: 'Camapign Name',
        value: 'campaignName',
        isFixed: true,
        width: "300",
        cn: ''  
    },
    {
        label: 'UTM Source',
        value: 'utmSource',
        width: "200",
        cn: ''  
    },
    {
        label: 'UTM Medium',
        value: 'utmMedium',
        width: "200",
        cn: ''  
    },
    {
        label: 'Total Leads',
        value: 'totalLeads',
        width: "200",
        cn: ''  
    },
    {
        label: 'Total Deals',
        value: 'totalDeals',
        width: "200",
        cn: ''  
    },
    {
        label: 'Conversion <br/> Ratio %',
        value: 'totalDealsRatioWithAverage',
        width: "200",
        cn: ''  
    },
    {
        label: 'Amount Spent',
        value: 'amountSpent',
        width: "200",
        cn: ''  
    },
    {
        label: 'Cost Per Lead',
        value: 'costPerLead',
        width: "200",
        cn: ''  
    },
    {
        label: 'Total Revenue',
        value: 'totalRevenue',
        width: "200",
        cn: ''  
    },
    {
        label: 'Credit Note',
        value: 'creditNotes',
        width: "200",
        cn: ''  
    },
    {
        label: 'Profitability',
        value: 'profitability',
        width: "200",
        cn: ''  
    },

    {
        label: 'ROAS',
        value: 'roas',
        width: "200",
        cn: ''  
    },
    {
        label: 'Avg. Policy <br/> Price',
        value: 'avgPolicyPrice',
        width: "200",
        cn: ''  
    },
    {
        label: 'Count of Basic <br/> Sold',
        value: 'basic',
        width: "200",
        cn: ''  
    },
    {
        label: 'Count of Enhanced <br/> Sold',
        value: 'enhance',
        width: "200",
        cn: ''  
    },
    {
        label: 'Count of Group <br/> Sold',
        value: 'group',
        width: "200",
        cn: ''  
    },
    {
        label: 'No. of Lost <br/> Leads',
        value: 'lostLeads',
        width: "200",
        cn: ''  
    },
    {
        label: 'Lost leads <br/> Ratio (%)',
        value: 'lostLeadsRatioWithAverage',
        width: "200",
        cn: ''  
    },
    {
        label: 'No. of WIP <br/> Leads(NL/PL)',
        value: 'wipLeads',
        width: "200",
        cn: '' ,
        isWip:true 
    },
    {
        label: 'WIP leads <br/> Ratio (%)',
        value: 'wipLeadsRatioWithAverage',
        width: "200",
        cn: ''  
    },
    {
        label: 'No. of New <br/> Leads(NL/QL)',
        value: 'newLeads',
        width: "200",
        cn: ''  
    },
    {
        label: 'New leads <br/> Ratio (%)',
        value: 'newLeadsRatioWithAverage',
        width: "200",
        cn: ''  
    },
    {
        label: 'No. of <br/>  Pending leads',
        value: 'pendingLeads',
        width: "200",
        cn: ''  
    },
    {
        label: 'Pending leads <br/> ratio (%)',
        value: 'pendingLeadsRatioWithAverage',
        width: "200",
        cn: ''  
    },
    {
        label: 'No. of <br/> Deleted leads',
        value: 'deletedLeads',
        width: "200",
        cn: ''  
    },
    {
        label: 'Deleted leads <br/> ratio (%)',
        value: 'deletedLeadsRatioWithAverage',
        width: "200",
        cn: ''  
    },
]

export const campaign_agent = [
    {
        label: 'Camapign Name',
        value: 'campaignName',
        isFixed: true,
        width: "300",
        cn: ''  
    },
    {
        label: 'UTM Source',
        value: 'utmSource',
        width: "200",
        cn: ''  
    },
    {
        label: 'UTM Medium',
        value: 'utmMedium',
        width: "200",
        cn: ''  
    },
    {
        label: 'Total Leads',
        value: 'totalLeads',
        width: "200",
        cn: ''  
    },
    {
        label: 'Total Deals',
        value: 'totalDeals',
        width: "200",
        cn: ''  
    },
    {
        label: 'Conversion <br/> Ratio %',
        value: 'totalDealsRatioWithAverage',
        width: "200",
        cn: ''  
    },
    {
        label: 'Cost of Lead',
        value: 'costOfLeads',
        width: "200",
        cn: ''  
    },
    {
        label: 'Cost of Deals',
        value: 'costOfDeals',
        width: "200",
        cn: ''  
    },
    {
        label: 'Total Revenue',
        value: 'totalRevenue',
        width: "200",
        cn: ''  
    },
    {
        label: 'Credit Note',
        value: 'creditNotes',
        width: "200",
        cn: ''  
    },
    {
        label: 'Profitability',
        value: 'profitability',
        width: "200",
        cn: ''  
    },

    {
        label: 'ROAS',
        value: 'roas',
        width: "200",
        cn: ''  
    },
    {
        label: 'Avg. Policy <br/> Price',
        value: 'avgPolicyPrice',
        width: "200",
        cn: ''  
    },
    {
        label: 'Count of Basic <br/> Sold',
        value: 'basic',
        width: "200",
        cn: ''  
    },
    {
        label: 'Count of Enhanced <br/> Sold',
        value: 'enhance',
        width: "200",
        cn: ''  
    },
    {
        label: 'Count of Group <br/> Sold',
        value: 'group',
        width: "200",
        cn: ''  
    },
    {
        label: 'No. of Lost <br/> Leads',
        value: 'lostLeads',
        width: "200",
        cn: ''  
    },
    {
        label: 'Lost leads <br/> Ratio (%)',
        value: 'lostLeadsRatioWithAverage',
        width: "200",
        cn: ''  
    },
    {
        label: 'No. of WIP <br/> Leads(NL/PL)',
        value: 'wipLeads',
        width: "200",
        cn: '' ,
        isWip:true 

    },
    {
        label: 'WIP leads <br/> Ratio (%)',
        value: 'wipLeadsRatioWithAverage',
        width: "200",
        cn: ''  
    },
    {
        label: 'No. of New <br/> Leads(NL/QL)',
        value: 'newLeads',
        width: "200",
        cn: ''  
    },
    {
        label: 'New leads <br/> Ratio (%)',
        value: 'newLeadsRatioWithAverage',
        width: "200",
        cn: ''  
    },

    {
        label: 'No. of <br/>  Pending leads',
        value: 'pendingLeads',
        width: "200",
        cn: ''  
    },
    {
        label: 'Pending leads <br/> ratio (%)',
        value: 'pendingLeadsRatioWithAverage',
        width: "200",
        cn: ''  
    },

    {
        label: 'No. of <br/> Deleted leads',
        value: 'deletedLeads',
        width: "200",
        cn: ''  
    },
    {
        label: 'Deleted leads <br/> ratio (%)',
        value: 'deletedLeadsRatioWithAverage',
        width: "200",
        cn: ''  
    },

]